// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messagingPrechat {
    background: whitesmoke;
    border-radius: inherit;
}

.prechatForm {
    height: 500px;
    border-bottom: 1px solid #ddd;
    overflow: auto;
    background: transparent;
    border-radius: inherit;
    font-family: inherit;
}

.prechatFormFieldsListView {
    height: inherit;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.prechatFormFieldContainer {
    min-width: 100px;
    padding: 0 14px;
    max-height: 100px;
    margin: 15px 0;
}

.prechatFormFieldName {
    color: black;
    font-size: medium;
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.prechatFormField {
    width: 100%;
    border: 1px solid;
}

.checkbox {
    width: 4%;
    margin-right: auto;
}

select {
    height: 35px;
    border-radius: 5px;
}

.requiredFieldIndicator {
    color: red;
    display: contents;
}

.startConversationButton {
    width: 75%;
    height: 35px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #888c8c;
    border: 1px;
    font-weight: bold;
}

.startConversationButton:hover {
    opacity: 0.8;
    pointer-events: all;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/prechat.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,cAAc;IACd,uBAAuB;IACvB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".messagingPrechat {\n    background: whitesmoke;\n    border-radius: inherit;\n}\n\n.prechatForm {\n    height: 500px;\n    border-bottom: 1px solid #ddd;\n    overflow: auto;\n    background: transparent;\n    border-radius: inherit;\n    font-family: inherit;\n}\n\n.prechatFormFieldsListView {\n    height: inherit;\n    margin: 0;\n    padding: 0;\n    list-style-type: none;\n}\n\n.prechatFormFieldContainer {\n    min-width: 100px;\n    padding: 0 14px;\n    max-height: 100px;\n    margin: 15px 0;\n}\n\n.prechatFormFieldName {\n    color: black;\n    font-size: medium;\n    margin: 0;\n    text-align: left;\n    display: flex;\n    flex-direction: column;\n}\n\n.prechatFormField {\n    width: 100%;\n    border: 1px solid;\n}\n\n.checkbox {\n    width: 4%;\n    margin-right: auto;\n}\n\nselect {\n    height: 35px;\n    border-radius: 5px;\n}\n\n.requiredFieldIndicator {\n    color: red;\n    display: contents;\n}\n\n.startConversationButton {\n    width: 75%;\n    height: 35px;\n    margin: 10px 0;\n    border-radius: 5px;\n    background-color: #888c8c;\n    border: 1px;\n    font-weight: bold;\n}\n\n.startConversationButton:hover {\n    opacity: 0.8;\n    pointer-events: all;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
