// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messagingFooter {
    width: 100%;
    display: flex;
    height: 50px;
    font-family: inherit;
    border-radius: inherit;
    background-color: whitesmoke;
}

.messagingFooter textarea {
    width: inherit;
    align-content: center;
    font-family: inherit;
    border-radius: 15px;
    margin: 5px;
    resize: none;
}

.sendButton {
    background: transparent;
    border: 0;
}

.sendButtonIcon{
    transform: scale(2);
    fill: rgb(98 91 91);
}

.sendButton:hover:not(:disabled){
    opacity: 0.7;
    cursor: pointer;
}

.sendButton:disabled {
    opacity: 0.3;
}

.messagingInputTextarea:disabled, .sendButton:disabled {
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/messagingInputFooter.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".messagingFooter {\n    width: 100%;\n    display: flex;\n    height: 50px;\n    font-family: inherit;\n    border-radius: inherit;\n    background-color: whitesmoke;\n}\n\n.messagingFooter textarea {\n    width: inherit;\n    align-content: center;\n    font-family: inherit;\n    border-radius: 15px;\n    margin: 5px;\n    resize: none;\n}\n\n.sendButton {\n    background: transparent;\n    border: 0;\n}\n\n.sendButtonIcon{\n    transform: scale(2);\n    fill: rgb(98 91 91);\n}\n\n.sendButton:hover:not(:disabled){\n    opacity: 0.7;\n    cursor: pointer;\n}\n\n.sendButton:disabled {\n    opacity: 0.3;\n}\n\n.messagingInputTextarea:disabled, .sendButton:disabled {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
