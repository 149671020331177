// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typingIndicatorContainer {
    display: flex;
    flex-direction: column;
}

.typingIndicatorBubbleContainer {
    padding-right: 10px;
    padding-left: 10px;
} 

.typingIndicatorBubble {
    float: left;
    min-width: 50px;
    max-width: 350px;
    width: -webkit-fit-content;
    background-color: rgb(158 154 154);
    border-radius: 20px;
    margin: 1px auto;
    padding: 4px 14px;
}

.typingIndicatorSenderContent {
    color: rgb(153, 153, 153);
    margin: 0px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    padding-right: 10px;
    padding-left: 10px;
}
 
 .loadingBall {
    display: inline-flex;
    text-align: center;
    margin: 0px 1.5px;
    max-width: 100%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    animation-delay: -0.5s;
    animation: typingIndicatorAnimation 2.5s infinite ease-in-out;
}

.second {
    animation-delay: 3s;
}

.third {
    animation-delay: 4s;
}

@keyframes typingIndicatorAnimation {
    0% {
        opacity: 0;
    }

    35% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/typingIndicator.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,0BAA0B;IAC1B,kCAAkC;IAClC,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;CAEC;IACG,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IACtB,6DAA6D;AACjE;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".typingIndicatorContainer {\n    display: flex;\n    flex-direction: column;\n}\n\n.typingIndicatorBubbleContainer {\n    padding-right: 10px;\n    padding-left: 10px;\n} \n\n.typingIndicatorBubble {\n    float: left;\n    min-width: 50px;\n    max-width: 350px;\n    width: -webkit-fit-content;\n    background-color: rgb(158 154 154);\n    border-radius: 20px;\n    margin: 1px auto;\n    padding: 4px 14px;\n}\n\n.typingIndicatorSenderContent {\n    color: rgb(153, 153, 153);\n    margin: 0px;\n    font-size: 10px;\n    font-weight: 400;\n    text-align: left;\n    padding-right: 10px;\n    padding-left: 10px;\n}\n \n .loadingBall {\n    display: inline-flex;\n    text-align: center;\n    margin: 0px 1.5px;\n    max-width: 100%;\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: white;\n    animation-delay: -0.5s;\n    animation: typingIndicatorAnimation 2.5s infinite ease-in-out;\n}\n\n.second {\n    animation-delay: 3s;\n}\n\n.third {\n    animation-delay: 4s;\n}\n\n@keyframes typingIndicatorAnimation {\n    0% {\n        opacity: 0;\n    }\n\n    35% {\n        opacity: 1;\n    }\n\n    50% {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
