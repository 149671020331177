// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textMessageSenderContent {
    color: rgb(153, 153, 153);
    margin: 0px;
    font-size: 10px;
    font-weight: 400;
}

.textMessageSenderContent[class~="incoming"] {
    text-align: left;
}

.textMessageSenderContent[class~="outgoing"] {
    text-align: right;
}

.textMessageBubbleContainer {
    overflow: auto;
}

.textMessageBubble {
    min-width: 50px;
    max-width: 350px;
    width: -webkit-fit-content;
    background-color: rgb(158 154 154);
    border-radius: 20px;
    margin: 1px auto;
    padding: 8px 14px;
}

.textMessageBubble[class~="incoming"] {
    float: left;
}

.textMessageBubble[class~="outgoing"] {
    float: right;
}

.textMessageContent {
    color: rgb(255, 255, 255);
    margin: 0px;
    font-size: 16px;
    font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/components/textMessage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;IAC1B,kCAAkC;IAClC,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".textMessageSenderContent {\n    color: rgb(153, 153, 153);\n    margin: 0px;\n    font-size: 10px;\n    font-weight: 400;\n}\n\n.textMessageSenderContent[class~=\"incoming\"] {\n    text-align: left;\n}\n\n.textMessageSenderContent[class~=\"outgoing\"] {\n    text-align: right;\n}\n\n.textMessageBubbleContainer {\n    overflow: auto;\n}\n\n.textMessageBubble {\n    min-width: 50px;\n    max-width: 350px;\n    width: -webkit-fit-content;\n    background-color: rgb(158 154 154);\n    border-radius: 20px;\n    margin: 1px auto;\n    padding: 8px 14px;\n}\n\n.textMessageBubble[class~=\"incoming\"] {\n    float: left;\n}\n\n.textMessageBubble[class~=\"outgoing\"] {\n    float: right;\n}\n\n.textMessageContent {\n    color: rgb(255, 255, 255);\n    margin: 0px;\n    font-size: 16px;\n    font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
