// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messagingBody {
    height: 500px;
    border-bottom: 1px solid #ddd;
    overflow: auto;
    background: whitesmoke;
    border-radius: inherit;
    font-family: inherit;
}

.conversationStartTimeText, .conversationEndTimeText {
    color: rgb(98 91 91);
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.conversationEntriesListView {
    height: auto;
    padding: 5px 10px;
    margin-top: 0;
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/messagingBody.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,cAAc;IACd,sBAAsB;IACtB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".messagingBody {\n    height: 500px;\n    border-bottom: 1px solid #ddd;\n    overflow: auto;\n    background: whitesmoke;\n    border-radius: inherit;\n    font-family: inherit;\n}\n\n.conversationStartTimeText, .conversationEndTimeText {\n    color: rgb(98 91 91);\n    margin-top: 10px;\n    font-size: 12px;\n    font-weight: bold;\n    text-align: center;\n}\n\n.conversationEntriesListView {\n    height: auto;\n    padding: 5px 10px;\n    margin-top: 0;\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
