// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messagingHeader {
    width: inherit;
    height: 50px;
    background-color: rgb(98 91 91);
    border-radius: 15px 15px 0px 0px;
    font-family: inherit;
}

.messagingHeaderCloseButton {
    float: right;
    margin-right: 20px;
    height: 50px;
    width: max-content;
    background: transparent;
    border: none;
    pointer-events: all;
    cursor: pointer;
}

.messagingHeaderCloseButtonIcon {
    transform: scale(2);
    fill: whitesmoke;
}

.messagingHeaderCloseButtonIcon:hover{
    opacity: 0.5;
    transform: scale(2);
}`, "",{"version":3,"sources":["webpack://./src/components/messagingHeader.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,+BAA+B;IAC/B,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".messagingHeader {\n    width: inherit;\n    height: 50px;\n    background-color: rgb(98 91 91);\n    border-radius: 15px 15px 0px 0px;\n    font-family: inherit;\n}\n\n.messagingHeaderCloseButton {\n    float: right;\n    margin-right: 20px;\n    height: 50px;\n    width: max-content;\n    background: transparent;\n    border: none;\n    pointer-events: all;\n    cursor: pointer;\n}\n\n.messagingHeaderCloseButtonIcon {\n    transform: scale(2);\n    fill: whitesmoke;\n}\n\n.messagingHeaderCloseButtonIcon:hover{\n    opacity: 0.5;\n    transform: scale(2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
