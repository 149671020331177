// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deploymentDetailsForm {
    display: flex;
    flex-direction: column;
}

input {
    height: 2rem;
    width: 20rem;
    align-self: center;
    border-radius: 5px;
}

.deploymentDetailsFormSubmitButton {
    width: 10rem;
    background-color: #3f51b5;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    outline: 0;
    border: 0; 
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
    transition: ease background-color 250ms;
    font-weight: bold;
    align-self: center;
}

.deploymentDetailsFormSubmitButton:hover:not(:disabled) {
    background-color: #283593;
}

.deploymentDetailsFormSubmitButton:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/bootstrapMessaging.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,iCAAiC;IACjC,uCAAuC;IACvC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".deploymentDetailsForm {\n    display: flex;\n    flex-direction: column;\n}\n\ninput {\n    height: 2rem;\n    width: 20rem;\n    align-self: center;\n    border-radius: 5px;\n}\n\n.deploymentDetailsFormSubmitButton {\n    width: 10rem;\n    background-color: #3f51b5;\n    color: white;\n    padding: 15px 30px;\n    border-radius: 5px;\n    outline: 0;\n    border: 0; \n    margin: 10px 0px;\n    cursor: pointer;\n    box-shadow: 0px 2px 2px lightgray;\n    transition: ease background-color 250ms;\n    font-weight: bold;\n    align-self: center;\n}\n\n.deploymentDetailsFormSubmitButton:hover:not(:disabled) {\n    background-color: #283593;\n}\n\n.deploymentDetailsFormSubmitButton:disabled {\n    cursor: not-allowed;\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
