// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversationEntryContainer {
    position: relative;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/conversationEntry.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".conversationEntryContainer {\n    position: relative;\n    overflow: auto;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
