// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messagingWindow {
    z-index: 9999999999;
    background-color: white;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    max-width: 550px;
    margin: 0 auto;
    border: 1px solid #ddd;
    /* position: fixed; */
    width: 550px;
    height: 600px;
    display: inline-block;
    bottom: 50px;
    right: 10px;
}

.messagingWindow[class~="hide"] {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/messagingWindow.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,2CAA2C;IAC3C,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".messagingWindow {\n    z-index: 9999999999;\n    background-color: white;\n    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);\n    border-radius: 15px;\n    max-width: 550px;\n    margin: 0 auto;\n    border: 1px solid #ddd;\n    /* position: fixed; */\n    width: 550px;\n    height: 600px;\n    display: inline-block;\n    bottom: 50px;\n    right: 10px;\n}\n\n.messagingWindow[class~=\"hide\"] {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
