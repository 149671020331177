// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participantChangeText {
    color: rgb(98 91 91);
    background-color: linen;
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    border: 1px solid rgb(153, 153, 153);
    border-radius: 10px;
    width: max-content;
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/participantChange.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,oCAAoC;IACpC,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".participantChangeText {\n    color: rgb(98 91 91);\n    background-color: linen;\n    margin-top: 10px;\n    font-size: 12px;\n    font-weight: bold;\n    text-align: center;\n    border: 1px solid rgb(153, 153, 153);\n    border-radius: 10px;\n    width: max-content;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
